import React from 'react';

const Kyrie = () => {
  return (
    <div
      className="background-image"
      style={{
        backgroundImage: `url(${require('../images/Kyrie.jpg')})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        padding: '36px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center'
      }}
    >
      <p
        style={{
          color: 'white',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '48px',
          textAlign: 'center',
          opacity: '0.9',
          marginBottom: '0'
        }}
      >
        Kyrie Ipsum
      </p>
      <div
        style={{
          backgroundColor: 'white',
          height: '2px',
          width: '360px',
          marginBottom: '36px'
        }}
      />
    </div>
  );
};

export default Kyrie;
