import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';

import * as actions from '../actions';
import icon from '../images/copy.png';

const marks = {
  1: <span style={{ opacity: '0.1' }}>1</span>,
  2: <span style={{ opacity: '0.2' }}>2</span>,
  3: <span style={{ opacity: '0.3' }}>3</span>,
  4: <span style={{ opacity: '0.4' }}>4</span>,
  5: <span style={{ opacity: '0.5' }}>5</span>,
  6: <span style={{ opacity: '0.6' }}>6</span>,
  7: <span style={{ opacity: '0.7' }}>7</span>,
  8: <span style={{ opacity: '0.8' }}>8</span>,
  9: <span style={{ opacity: '0.9' }}>9</span>,
  10: <strong>10</strong>
};

class Ipsum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false
    };
  }

  handleChangeSliderValue = value => {
    const { changeSliderValue } = this.props;
    changeSliderValue(value);
  };

  handleCopyToClipboard = () => {
    const paragraphs = document.getElementsByClassName('ipsum-paragraph');
    let ipsumText = '';

    for (const p of paragraphs) {
      ipsumText += `${p.innerHTML}\r\n\r\n`;
    }

    this.createTextAreaEl(ipsumText);
  };

  createTextAreaEl(ipsumText) {
    const textarea = document.createElement('textarea');
    textarea.value = ipsumText.trim();
    document.body.appendChild(textarea);
    textarea.select();
    const result = document.execCommand('copy');
    document.body.removeChild(textarea);
    this.setState({ showToast: true });
    return result;
  }

  render() {
    const { showToast } = this.state;
    const { ipsum } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '36px'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Toast
            onClose={() => this.setState({ showToast: false })}
            show={showToast}
            delay={3000}
            autohide
            style={{ position: 'absolute', top: '12px', right: '140px' }}
          >
            <Toast.Header>
              <strong className="mr-auto">Copied to clipboard!</strong>
            </Toast.Header>
          </Toast>
          <Button
            className="d-none d-md-block"
            variant="light"
            style={{
              marginTop: '-48px',
              position: 'absolute',
              right: '48px'
            }}
            onClick={this.handleCopyToClipboard}
          >
            Copy&nbsp;
            <img
              src={icon}
              height={16}
              width={16}
              style={{ marginBottom: '4px' }}
              alt="Copy to clipboard"
            />
          </Button>
        </div>

        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>
          Wokeness Level
        </h3>
        <Slider
          min={1}
          max={10}
          step={1}
          defaultValue={2}
          marks={marks}
          onChange={this.handleChangeSliderValue}
        />
        <div style={{ marginTop: '48px' }}>
          {ipsum &&
            ipsum.map(paragraph => {
              return (
                <p className="ipsum-paragraph" key={paragraph}>
                  {paragraph}
                </p>
              );
            })}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(Ipsum);

Ipsum.propTypes = {
  changeSliderValue: PropTypes.func.isRequired,
  ipsum: PropTypes.arrayOf(PropTypes.string)
};
