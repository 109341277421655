import _ from 'lodash';

const sentencesInParagraph = 5;

export default (characterQuotes, numParagraphs) => {
  const shuffledQuotes = _.shuffle(characterQuotes);
  return _.chunk(shuffledQuotes, sentencesInParagraph)
    .slice(0, numParagraphs)
    .map(chunk => _.join(chunk, ' '));
};
