import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import Kyrie from './Kyrie';
import Ipsum from './Ipsum';
import './App.css';

import data from '../quotes';
import generateIpsum from '../utils/generateIpsum';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipsum: null
    };
  }

  componentDidMount() {
    const { numberOfParagraphs } = this.props;
    this.generateIpsum(numberOfParagraphs);
  }

  componentWillReceiveProps(nextProps) {
    const { numberOfParagraphs } = nextProps;
    this.generateIpsum(numberOfParagraphs);
  }

  generateIpsum(numberOfParagraphs) {
    const ipsum = generateIpsum(data.Kyrie, numberOfParagraphs);
    this.setState({ ipsum });
  }

  render() {
    const { ipsum } = this.state;
    return (
      <Container className="App">
        <Row>
          <Col className="kyrie-container" xs={12} md={5}>
            <Kyrie id="test" />
          </Col>
          <Col className="ipsum-container" xs={12} md={7}>
            <Ipsum ipsum={ipsum} />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    numberOfParagraphs: state.numberOfParagraphs
  };
};

export default connect(mapStateToProps)(App);

App.propTypes = {
  numberOfParagraphs: PropTypes.number.isRequired
};
