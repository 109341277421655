import { CHANGE_SLIDER_VALUE } from '../actions/types';

const initialState = 2;

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SLIDER_VALUE:
      return action.payload;
    default:
      return state;
  }
}
